<template>
    <h1 style="text-align: center">Username: {{username}}</h1>
    <h2 style="text-align: center">Current Permissions: {{permissions}}</h2>
    <h2 style="text-align: center">Email: {{email}}</h2>
    <h2 style="text-align: center">FH: {{$tenant}}</h2>
    <br>
    <div style="display: flex; align-items: center; justify-content: center">
      <button style="width: 30%; margin: auto" v-on:click='signOut' class='btn btn-danger btn-block'>Sign Out</button>
    </div>
</template>

<script>
import Auth from '@aws-amplify/auth';
import { db } from '../firebaseDb';
export default {
  name: "App",
  data() {
            return {
                username: "",
                permissions: "",
                email: "",
            }
        },
  created() {
  console.log(this.$tenant)
  Auth.currentAuthenticatedUser({
            }).then(user => { 
              this.username = user.username;
              db.collection('pendingusers').onSnapshot((snapshotChange) => {
                snapshotChange.forEach((doc) => {
                    if(String(doc.data().username).toLowerCase() == String(user.username).toLowerCase()) {
                        this.email = doc.data().email
                        if(doc.data().approved == 'true') {
                          this.permissions = "Full Access"
                        }
                        if(doc.data().approved == 'false') {
                          this.permissions = "No Access"
                        }
                        if(doc.data().approved == 'view') {
                          this.permissions = "View Only"
                        }
                        if(doc.data().approved == 'admin') {
                          this.permissions = "Admin"
                        }
                    }
                })
                })
            });
  },
  methods: {
   async signOut() {
      await Auth.signOut().then(() => {
        console.log(Auth.currentAuthenticatedUser())
        window.location.reload();
      })
  },
  },
  
};
</script>